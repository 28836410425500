.swiper-pagination {
    bottom: 0 !important;
}

.center .slick-track {
    display: flex !important;
    align-items: center;
}

.center .slick-center img {
    width: 8.5rem;
    height: 8.5rem;
}

@media (max-width: 991px) {
    .center .slick-center img {
        width: 150px;
        height: 150px;
    }
}

.slick-prev, .slick-next {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    color: rgba(0, 0, 0, 0);
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 25px;
    outline: medium none;
    padding: 0;
    position: absolute;
    top: 34.6%;
    width: 16px;
}

.slick-prev {
    left: -31px
}

.slick-next {
    right: -31px
}
