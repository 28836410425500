.dropdown-content h1 {
    font-size: 20px;
    font-weight: 600;
    padding-left: 1rem;
    margin: 0;
}

.product {
    display: flex;
}

.img-product {
    width: 5rem;
    height: auto;
}

.img-product img {
    width: 100%;
}

.product-info {
    line-height: 0.5;
}
.product-info h4 {
    max-width: 90%;
    margin: 0;
}
.product-info span {
    font-size: 10px;
}

.precios > div{
    line-height: 1;
    padding: 0 .7rem;
}
