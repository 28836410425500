.mySwiperClientes{
    width: 75%;
}
.mySwiperClientes{
    display: flex;
    justify-content: center;
}

.mySwiperClientes .swiper-slide {
    text-align: center;
}

.swiper-wrapper{
    align-items: center;
}

@media (max-width: 991px){
    .mySwiperClientes{
        width: 100%;
    }
}